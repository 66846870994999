
import { apiPackageItemDel, apiDownloadLogLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class Item extends Vue {
    queryObj = {
		goods_id: this.$route.query.id,
    }
	
    pager = new RequestPaging()
    apiDownloadLogLists = apiDownloadLogLists
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiDownloadLogLists,
            params: {
                ...this.queryObj
            }
        })
    }
    handleReset() {
        this.queryObj = {
			goods_id: this.$route.query.id,
        }
        this.getList()
    }
	
    handleDelete(id: number) {
        apiPackageItemDel(id).then(() => {
            this.getList()
        })
    }

    created() {
		console.log( this.queryObj )
        this.getList()
    }
}
